import React from "react";
import classNames from "classnames";
import { Button, ButtonVariants } from "@shared-tailwind/atoms/Button/Button";
import { ButtonSizes } from "@shared-tailwind/types";

type TProps = Omit<React.ComponentProps<typeof Button>, "variant" | "size" | "isRounded">;

export const Shortcut: React.FC<TProps> = ({ className, ...props }) => {
  return (
    <Button
      {...props}
      className={classNames(
        "text-primary-500 border px-4 min-w-[100px] rounded-lg whitespace-nowrap",
        className
      )}
      isRounded={false}
      size={ButtonSizes.sm}
      variant={ButtonVariants.link}
    />
  );
};
