import React from "react";
import { INearbyWidgetViewProps } from "@core-shop/components";
import { routes } from "@shared/utils";
import colors from "tailwindcss/colors";
import { BrandCircle, Loader } from "@shared-tailwind/atoms";
import { BlocksCarousel } from "@shared-tailwind/molecules";

const MIN_CIRCLES = 4;

export const ShopNearby: React.FC<INearbyWidgetViewProps> = ({
  locationSearchQuery,
  formattedBrands = [],
}) => {
  return (
    <BlocksCarousel buttonTitle="See all" buttonUrl="#" className="py-3" title="Nearby">
      {locationSearchQuery.isLoading && (
        <div className="mt-5 min-h-[100px]">
          <Loader size={60} />
        </div>
      )}
      {formattedBrands?.map(({ id, images, name, location }) => (
        <BrandCircle
          bgColor={images.logoBackgroundColor ?? colors.white}
          href={routes.shopcloud.brandModal(id, location?.id)}
          key={id}
          name={name}
          src={images?.invertedLogo || images.logo || ""}
        />
      ))}
      {!locationSearchQuery.isLoading &&
        formattedBrands.length < MIN_CIRCLES &&
        Array(MIN_CIRCLES - formattedBrands.length)
          .fill("empty")
          .map((name, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <BrandCircle bgColor={colors.gray[100]} key={`${name as string}-${i}`} name={name} />
          ))}
    </BlocksCarousel>
  );
};
