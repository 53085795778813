// eslint-disable-next-line import/no-duplicates
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
// eslint-disable-next-line import/no-duplicates
import parseISO from "date-fns/parseISO";

export function getDaysAgo(timeISO: string) {
  const daysAgo = Math.abs(differenceInCalendarDays(parseISO(timeISO), new Date()));
  let dateString = "";
  switch (daysAgo) {
    case 0:
      dateString = "today";
      break;
    case 1:
      dateString = "yesterday";
      break;
    default:
      dateString = `${daysAgo} days ago`;
  }

  return dateString;
}
