import { ReactNode } from "react";
import { PLACEHOLDER_AVATAR } from "@shared/utils";
import classNames from "classnames";
import { Avatar, TAvatarSizes } from "@shared-tailwind/atoms";

// == Types ================================================================

interface IProps {
  avatarProps?: Partial<React.ComponentProps<typeof Avatar>>;
  avatarSize?: TAvatarSizes;
  className?: string;
  CustomAvatar?: ReactNode;
  hasAvatar?: boolean;
  isCentered?: boolean;
  isTruncated?: boolean;
  onClick?: () => void;
  src?: string | React.FC<$IntentionalAny> | null;
  textBottom?: ReactNode | string;
  textTop?: ReactNode | string;
  textUppercased?: string;
}

// == Constants ============================================================

AvatarWithText.defaultProps = {
  src: PLACEHOLDER_AVATAR,
  textTop: "",
  textBottom: "",
  avatarSize: 10,
};

const sharedTextClasses = "text-left font-medium";

// == Component ============================================================

export function AvatarWithText({
  src,
  avatarSize,
  textTop,
  textBottom,
  textUppercased,
  className,
  CustomAvatar,
  avatarProps,
  isTruncated,
  isCentered,
  onClick,
  hasAvatar = true,
}: IProps) {
  return (
    <div
      className={classNames("block text-sm", onClick && "cursor-pointer", className)}
      role="button"
      tabIndex={-1}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <div className={classNames("flex", { "items-center": !textTop || !textBottom || isCentered })}>
        {hasAvatar && (
          <div className="shrink-0">
            {CustomAvatar ?? <Avatar size={avatarSize} src={src} {...avatarProps} />}
          </div>
        )}

        <div className={classNames("w-full", hasAvatar && "ml-3")}>
          {textUppercased && (
            <div
              className={classNames(
                sharedTextClasses,
                "text-xs text-gray-500 uppercase",
                isTruncated && "whitespace-nowrap truncate"
              )}
            >
              {textUppercased}
            </div>
          )}
          {textTop && (
            <div
              className={classNames(
                sharedTextClasses,
                "text-gray-700",
                isTruncated && "whitespace-nowrap truncate"
              )}
            >
              {textTop}
            </div>
          )}
          {textBottom && (
            <div
              className={classNames(
                sharedTextClasses,
                "text-xs text-gray-500",
                isTruncated && "whitespace-nowrap truncate"
              )}
            >
              {textBottom}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// == Styles ===============================================================
