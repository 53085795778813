import React from "react";
import { INewsViewProps } from "@core-shop/components";
import { getDaysAgo, getUrlFromAssetDestination } from "@shared/utils";
import { Container, Loader, NewsBlock, Slider } from "@shared-tailwind/atoms";
import { HeadingSection } from "@shared-tailwind/molecules";

const MAX_COUNT = 5;

export const ShopNewsCarousel: React.FC<INewsViewProps> = ({ newsQuery, news, onLikeNewsMutation }) => {
  if (newsQuery.isLoading)
    return (
      <div className="mt-5 min-h-[100px]">
        <Loader size={60} />
      </div>
    );

  return (
    <>
      <HeadingSection hasNoBorder>Just for you</HeadingSection>
      <Slider>
        {news?.slice(0, MAX_COUNT).map(({ id, details, viewerHasLiked: isLiked, time, likesCount }) => {
          const destinationParsed = getUrlFromAssetDestination(details.destination ?? "");
          return (
            <Container className="py-4 border rounded-lg bg-gray-100" key={id}>
              <NewsBlock
                body={details.description ?? ""}
                buttonText={details.ctaText ?? ""}
                buttonUrl={destinationParsed.toString()}
                className="w-full h-[470px] flex flex-col"
                date={getDaysAgo(time)}
                id={id}
                imageSrc={details.photoImage ?? ""}
                isLiked={isLiked}
                likesCount={likesCount}
                onLikeNewsMutation={onLikeNewsMutation}
              />
            </Container>
          );
        })}
      </Slider>
    </>
  );
};
