import React, { useState, useEffect, useMemo } from "react";
import { ArrayElement } from "@shared/types";
import { PLACEHOLDER_AVATAR } from "@shared/utils";
import classNames from "classnames";
import { IImageProps, Image } from "../Image/Image";

// == Types ================================================================

export const avatarSizes = [6, 8, 10, 12, 14, 16, 24, 32] as const;
export type TAvatarSizes = ArrayElement<typeof avatarSizes>;

export enum AvatarNotificationColors {
  gray = "gray",
  green = "green",
  red = "red",
}

interface IProps {
  notificationColor: AvatarNotificationColors;
  size: TAvatarSizes;
  src: string | React.FC<$IntentionalAny> | null;
  alt?: string;
  className?: string;
  hasNotification?: boolean;
  isRect?: boolean;
  onClick?: () => void;
}

// == Constants ============================================================

Avatar.defaultProps = {
  src: PLACEHOLDER_AVATAR,
  notificationColor: AvatarNotificationColors.red,
  size: 10,
};

// == Functions ============================================================

function useAvatarSizes(size: TAvatarSizes): { classes: string; pixelsSize: number } {
  const [pixelsSize, setPixelsSize] = useState<number>(0);
  const [classes, setClasses] = useState<string>("");

  useEffect(() => {
    const avatarSize = size;
    setPixelsSize(avatarSize * 4);
    setClasses(`h-${avatarSize} w-${avatarSize}`);
  }, [size]);

  return { pixelsSize, classes };
}

// == Component ============================================================

export function Avatar({
  src,
  isRect,
  hasNotification,
  notificationColor,
  size,
  className,
  onClick,
  alt,
}: IProps) {
  const { classes, pixelsSize } = useAvatarSizes(size);
  const isBasicSrc = !src || typeof src === "string";
  const AvatarImage = useMemo(() => {
    if (isBasicSrc) return (props: IImageProps) => <Image alt="avatar" {...props} />;

    const Icon = src;
    return (props: IImageProps) => <Icon {...props} />;
  }, [isBasicSrc, src]);

  const hasIconSrc = src && typeof src !== "string";

  return (
    <div
      className={classNames("relative flex items-center shrink-0", !onClick && "cursor-default", className)}
      role="button"
      tabIndex={-1}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <AvatarImage
        alt={alt}
        className={classNames(
          "inline-block ",
          !hasIconSrc && {
            "rounded-full": !isRect,
            "rounded-md": isRect,
          },
          classes
        )}
        imagePositionClassName="object-cover object-center"
        imageProps={{ className: classNames(classes, "rounded-full"), width: pixelsSize, height: pixelsSize }}
        src={isBasicSrc ? (src as string) || PLACEHOLDER_AVATAR : PLACEHOLDER_AVATAR}
      />
      {hasNotification && (
        <span
          className={classNames(
            "absolute top-0 right-0 block h-2.5 w-2.5",
            "rounded-full ring-2 ring-white h-",
            `bg-${notificationColor}-400`
          )}
        />
      )}
    </div>
  );
}

// == Styles ===============================================================
