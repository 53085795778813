import React from "react";
import { IFavoritesViewProps } from "@core-shop/components";
import { routes } from "@shared/utils";
import colors from "tailwindcss/colors";
import { BrandCircle, Loader } from "@shared-tailwind/atoms";
import { BlocksCarousel } from "@shared-tailwind/molecules";

const MIN_CIRCLES = 4;

export const ShopFavoritesWidget: React.FC<IFavoritesViewProps> = ({ brands, isLoading }) => {
  return (
    <BlocksCarousel
      buttonTitle="See all"
      buttonUrl={routes.shopcloud.favorites()}
      className="py-3"
      title="Favorites"
    >
      {isLoading && (
        <div className="mt-5 min-h-[100px]">
          <Loader size={60} />
        </div>
      )}
      {brands.map(({ id, images, name }) => (
        <BrandCircle
          bgColor={images.logoBackgroundColor ?? colors.white}
          href={routes.shopcloud.brandModal(id)}
          key={id}
          name={name}
          src={images?.invertedLogo || images.logo || ""}
        />
      ))}
      {!isLoading &&
        brands.length < MIN_CIRCLES &&
        Array(MIN_CIRCLES - brands.length)
          .fill("empty")
          .map((name, i) => (
            <BrandCircle
              bgColor={colors.gray[100]}
              href={routes.shopcloud.favorites()}
              // eslint-disable-next-line react/no-array-index-key
              key={`${name as string}-${i}`}
              name={name}
            />
          ))}
    </BlocksCarousel>
  );
};
