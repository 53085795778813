import React from "react";
import { IShortcutsViewProps } from "@core-shop/components";
import { HorizontalScroll, InternalLink, Shortcut } from "@shared-tailwind/atoms";

export const ShopShortcuts: React.FC<IShortcutsViewProps> = ({ shortcutsQuery, shortcuts }) => {
  if (shortcutsQuery.isLoading) return null;

  return (
    <HorizontalScroll className="px-3 py-5">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore TODO: del when Ale will push shortcuts */}
      {shortcuts.map(({ name, shortcutUrl, id }) => (
        <InternalLink href={shortcutUrl ?? ""} key={`shortcut-${id}`}>
          <Shortcut>{name}</Shortcut>
        </InternalLink>
      ))}
    </HorizontalScroll>
  );
};
