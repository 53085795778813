import {
  AuthenticatedHeader,
  Favorites,
  NearbyWidget,
  News,
  Shortcuts,
  Storefront,
} from "@core-shop/components";
import { useAuthContext } from "@providers/AuthProvider/authContext";
import { Banner, Container, InternalLink } from "@shared-tailwind/atoms";
import { SearchInput } from "@shared-tailwind/molecules";
import {
  ShopFavoritesWidget,
  ShopHeader,
  ShopNearby,
  ShopNewsCarousel,
  ShopShortcuts,
  ShopStorefront,
} from "@shared-tailwind/organisms";
import { DEFAULT_ORG_IMAGE_URL, routes } from "@shared/utils";

const pageConfig: IPageConfig = {
  theme: "SHOP_NEW",
  hasGraphql: false,
};

Index.pageConfig = pageConfig;

function Index() {
  const { isAuthorized, shouldOptIn } = useAuthContext();

  return (
    <>
      <AuthenticatedHeader isAuthorized={isAuthorized} View={ShopHeader} />
      <div className="bg-gray-100 pb-3 mb-5">
        <Container>
          <InternalLink shallow href={routes.shopcloud.searchModal()}>
            <SearchInput placeholder="Search" />
          </InternalLink>
        </Container>
      </div>
      <Container>
        <Banner className="aspect-[4/3] bg-slate-100" name="Test banner" src={DEFAULT_ORG_IMAGE_URL} />
      </Container>
      <Container className="border-y mt-6">
        <Shortcuts View={ShopShortcuts} />
      </Container>
      {isAuthorized && (
        <>
          <Container className="border-b">
            {!shouldOptIn && <Favorites View={ShopFavoritesWidget} />}
          </Container>
          <Container className="border-b">
            <NearbyWidget View={ShopNearby} />
          </Container>
          <Container className="border-b my-6">
            <News View={ShopNewsCarousel} />
          </Container>
        </>
      )}
      <Storefront View={ShopStorefront} />
    </>
  );
}

export default Index;
