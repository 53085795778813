import React, { useCallback, useState } from "react";
import { INewsViewProps } from "@core-shop/components";
import { ReactionType } from "@core-shop/types";
import { ThumbUpIcon as LikeOutline } from "@heroicons/react/outline";
import { ThumbUpIcon as LikeSolid } from "@heroicons/react/solid";
import { Button, ButtonVariants } from "../Button/Button";
import { Image } from "../Image/Image";
import { InternalLink } from "../InternalLink/InternalLink";

interface IProps {
  body: string;
  date: string;
  id: string;
  imageSrc: string;
  buttonText?: string;
  buttonUrl?: string;
  className?: string;
  isLiked?: boolean;
  likesCount?: number;
  onLikeNewsMutation?: INewsViewProps["onLikeNewsMutation"];
}

export const NewsBlock: React.FC<IProps> = ({
  date = "",
  body,
  imageSrc,
  buttonText,
  className = "w-[292px]",
  isLiked,
  buttonUrl,
  likesCount,
  onLikeNewsMutation,
  id,
}) => {
  const [hasInnerLike, setHasInnerLike] = useState(isLiked);
  const [isInnerLikeLoading, setIsInnerLikeLoading] = useState(false);
  const [innerLikesCount, setInnerLikesCount] = useState(likesCount);

  const onLike = useCallback(async () => {
    setIsInnerLikeLoading(true);
    const data = await onLikeNewsMutation?.mutateAsync({
      activityId: id,
      reactionType: hasInnerLike ? ReactionType.DISLIKE : ReactionType.LIKE,
    });
    if (data?.ActivityReactionCreate?.__typename !== "MutationError" && data?.ActivityReactionCreate.id) {
      setHasInnerLike(data.ActivityReactionCreate.viewerHasLiked);
      setInnerLikesCount(data.ActivityReactionCreate.likesCount);
    }
    setIsInnerLikeLoading(false);
  }, [hasInnerLike, id, onLikeNewsMutation]);

  return (
    <div className={className}>
      {imageSrc && (
        <Image
          alt={body}
          className="w-full h-[300px] rounded-lg overflow-hidden"
          imagePositionClassName="object-cover"
          imageProps={{ className: "w-full h-full rounded-b-lg" }}
          src={imageSrc}
        />
      )}
      <p className="text-gray-900 mt-4 line-clamp-4">{body}</p>
      <div className="flex-1 flex flex-col justify-end">
        <div className="flex justify-between items-center mt-4 ">
          <p className="text-gray-400">{date}</p>
          <div className="flex space-x-2">
            <Button
              resetSizeClasses
              className="px-2 py-1"
              Icon={hasInnerLike ? LikeSolid : LikeOutline}
              iconClassName="text-primary-500 w-6 h-6"
              isLoading={isInnerLikeLoading}
              variant={ButtonVariants.secondary}
              onClick={onLike}
            >
              {!!innerLikesCount && <span className="text-gray-600">{innerLikesCount}</span>}
            </Button>
            {/* for future shop 2.0 */}
            {/* <Button
              resetSizeClasses
              className="px-2 py-1"
              Icon={ChatIcon}
              iconClassName="text-primary-500 w-6 h-6"
              variant={ButtonVariants.secondary}
            /> */}
            <InternalLink href={buttonUrl ?? ""}>
              <Button resetSizeClasses className="px-3 py-1">
                {buttonText}
              </Button>
            </InternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};
