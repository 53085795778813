import React from "react";
import { IAuthenticatedHeaderViewProps } from "@core-shop/components";
import { UserIcon, BellIcon, CreditCardIcon } from "@heroicons/react/outline";
import { routes } from "@shared/utils";
import { Container, InternalLink, Loader } from "@shared-tailwind/atoms";
import { AvatarWithText } from "@shared-tailwind/molecules";

export const ShopHeader: React.FC<IAuthenticatedHeaderViewProps> = ({
  amountRaised,
  logo,
  name,
  isLoading,
  hasUser,
}) => {
  return (
    <div className="w-full py-3 bg-gray-100">
      <Container className="flex justify-between items-center">
        {isLoading ? (
          <Loader size={48} />
        ) : (
          <AvatarWithText
            isCentered
            avatarProps={{ className: "border rounded-full bg-gray-300" }}
            avatarSize={10}
            src={logo.split("?")[0]}
            textBottom={
              <InternalLink className="text-lg text-primary-500" href={routes.shopcloud.funds()}>
                {amountRaised}
              </InternalLink>
            }
            textTop={name}
          />
        )}
        {hasUser && (
          <div className="flex space-x-3 text-gray-400">
            <InternalLink href={routes.shopcloud.news()}>
              <BellIcon className="w-7 h-7" />
            </InternalLink>
            <InternalLink href={routes.shopcloud.wallet()}>
              <CreditCardIcon className="w-7 h-7" />
            </InternalLink>
            <InternalLink href={routes.shopcloud.teamDashboard()}>
              <UserIcon className="w-7 h-7" />
            </InternalLink>
          </div>
        )}
      </Container>
    </div>
  );
};
