import React from "react";
import { IStorefrontViewProps } from "@core-shop/components/storefront/IStorefrontViewProps";
import { useRouter } from "@shared/hooks";
import { Container, Loader } from "@shared-tailwind/atoms";
import { BackTopNav } from "@shared-tailwind/molecules";
import { ShopCollection } from "../ShopCollection/ShopCollection";

interface IProps extends IStorefrontViewProps {
  hasHeader?: boolean;
}

export const ShopStorefront: React.FC<IProps> = ({
  collections,
  hasHeader,
  title,
  storefrontQuery,
  toggleFavoriteMutation,
  shouldShowLikeButton,
}) => {
  const router = useRouter();

  return (
    <>
      {hasHeader && (
        <BackTopNav hasBackText={false} hasBottomSpace={false} title={title} onClick={router.safeBack} />
      )}
      <Container className="pb-10">
        {storefrontQuery.isLoading && (
          <div className="flex justify-center">
            <Loader size={96} />
          </div>
        )}
        {collections.map((collection) => (
          <ShopCollection
            collection={collection}
            key={collection.id}
            shouldShowLikeButton={!!shouldShowLikeButton}
            toggleFavoriteMutation={toggleFavoriteMutation}
          />
        ))}
      </Container>
    </>
  );
};
